<!--
 * @Author: SongYijie
 * @Date: 2020-05-11 14:53:44
 * @LastEditors: SongYijie
-->
<template>
  <div class='wrap'>
    <mt-header title="开票申请单列表">
      <router-link to="" slot="left">
        <mt-button icon="back" @click.native="$router.back(-1)"></mt-button>
      </router-link>
      <img slot="right" width="21" src="@assets/images/individualBusiness/add.png" alt="home" @click="handleAdd">
    </mt-header>
    <div v-if="this.list.length > 0" class="invoice-apply">
      <div class="invoice-apply-item" v-for="(item, index) in this.list" :key="index" >
        <div class="title" @click="handleDetail(item.id)">
          <p class="info">
            <span>{{`${item.providerName || ''}`}}</span>
            <span>{{`￥${item.amt || 0}` }}</span>
          </p>
          <div class="triangle">
            <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path></svg>
          </div>
        </div>
      </div>
    </div>
    <p v-else class="empty">
      暂无数据
    </p>
  </div>
</template>

<script>
import { getInvoiceApplyList } from '@api/individualBusiness';
export default {
  name: "invoice-apply",
  components: {
  },
  data() {
    return {
      list: []
    };
  },
  mounted() {
    getInvoiceApplyList().then(res => {
      if (res.code === 200) {
        this.list = res.data || [];
      } else {
        Toast(res.message);
      }
    })
  },
  beforeDestroy() {},
  computed: {},
  methods: {
    handleAdd() {
      this.$router.push('/individual-business/invoice-apply-add')
    },
    handleDetail(id) {
      this.$router.push('/individual-business/invoice-apply-detail/' + id)
    }
  }
};
</script>

<style lang="less" scoped>
.wrap {
  min-height: 100vh;
  background-color: #f0f2f5;
}
.invoice-apply {
  padding: 2.9333vw 0 5.3333vw;
}
.invoice-apply-item {
  box-sizing: border-box;
  width: 100%;
  padding: 1.2vw 4.8vw;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 4.2667vw;
  height: 13.3333vw;
  line-height: 12vw;
  padding-left: 6.1333vw;
  color: rgba(0,0,0,.75);
  border-radius: 3.4667vw;
  box-shadow: 0.4vw 0.4vw 1vw 0.43vw rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
}
.info {
  width: calc(100% - 14vw);
  font-weight: bold;
  color: rgba(35,24,21,1);
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  padding-right: 2vw;

  > span {
    &:nth-of-type(1) {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
.triangle {
  width: 12vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to bottom right, #2ba2f9, #47e2f8);
  border-top-right-radius: 3.4667vw;
  border-bottom-right-radius: 3.4667vw;
}
svg {
  transition: all .3s ease;
  color: #ffffff;
}
.empty {
  text-align: center;
  margin: 24vw 0;
  font-size: 4.9333vw;
  font-weight: bold;
  color: rgba(51,51,51,1);
}
</style>